import { Button, Col, Form, Row } from "antd";
import { useForm } from "antd/lib/form/Form";
import { AppInputNumber } from "components";
import { TBodyIndexModalProps } from "components/BodyIndexModal/BodyIndexModal";
import { calculateBMI, toFixedNumber } from "helpers";
import { useAppSelector } from "hooks";
import { TBodyIndexInfo } from "model/member";
import { Fragment, useEffect } from "react";
import { ValidateErrorEntity } from "types/antd";
import { BODY_INDEX_FORM_CONFIGS, FORM_NAME } from "./BodyIndexForm.constant";
import { ActionWrapperStyled, FormStyled } from "./BodyIndexForm.styled";
import {
  MAX_LENGTH_BODY_FAT,
  MAX_LENGTH_HEART_BEAT,
  MAX_LENGTH_HEIGHT,
  MAX_LENGTH_PRESSURE_DIASTOLIC,
  MAX_LENGTH_PRESSURE_SYSTOLIC,
  MAX_LENGTH_WEIGHT,
} from "constants/common";

function formatBodyIndexValue(values: TBodyIndexInfo): TBodyIndexInfo {
  const memberBMI = calculateBMI({
    height: values.height,
    weight: values.weight,
  });

  return {
    ...values,
    bodyFat: toFixedNumber(values.bodyFat) || undefined,
    height: toFixedNumber(values.height) || undefined,
    weight: toFixedNumber(values.weight) || undefined,
    maxBloodPressure: toFixedNumber(values.maxBloodPressure) || undefined,
    minBloodPressure: toFixedNumber(values.minBloodPressure) || undefined,
    heartbeat: toFixedNumber(values.heartbeat) || undefined,
    bmi: toFixedNumber(memberBMI, 1) || undefined,
  };
}

type TBodyIndexFormProps = Pick<
  TBodyIndexModalProps,
  "submitText" | "onSubmit" | "initialValues"
>;

function BodyIndexForm(props: TBodyIndexFormProps) {
  const { onSubmit, submitText = "保存", initialValues } = props;

  const [form] = useForm<TBodyIndexInfo>();
  const { store } = useAppSelector((state) => state.auth.user) || {};

  const handleFinish = (values: TBodyIndexInfo) => {
    const formattedValues = formatBodyIndexValue(values);

    onSubmit(formattedValues);
  };

  const handleFinishFailed = (
    errorInfo: ValidateErrorEntity<TBodyIndexInfo>
  ) => {
    const firstFiledError = errorInfo.errorFields[0].name[0];
    form.getFieldInstance(firstFiledError)?.focus();
  };

  // Effects
  useEffect(() => {
    form.getFieldInstance(BODY_INDEX_FORM_CONFIGS.WEIGHT.name || "")?.focus();
  }, []);

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues]);

  return (
    <Fragment>
      <FormStyled
        form={form}
        name={FORM_NAME}
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 19 }}
        labelAlign="left"
        requiredMark={false}
        onFinish={handleFinish}
        onFinishFailed={handleFinishFailed}
      >
        {/* Store Id */}
        <Form.Item name="storeId" hidden initialValue={store?.id}>
          <input />
        </Form.Item>

        {/* Height */}
        <Form.Item {...BODY_INDEX_FORM_CONFIGS.HEIGHT}>
          <AppInputNumber
            suffix={BODY_INDEX_FORM_CONFIGS.HEIGHT.unit}
            isLimitDecimal
            maxLengthInput={MAX_LENGTH_HEIGHT}
          />
        </Form.Item>

        {/* Weight */}
        <Form.Item {...BODY_INDEX_FORM_CONFIGS.WEIGHT}>
          <AppInputNumber
            suffix={BODY_INDEX_FORM_CONFIGS.WEIGHT.unit}
            autoFocus
            isLimitDecimal
            maxLengthInput={MAX_LENGTH_WEIGHT}
          />
        </Form.Item>

        {/* Body fat */}
        <Form.Item {...BODY_INDEX_FORM_CONFIGS.BODY_FAT}>
          <AppInputNumber
            suffix={BODY_INDEX_FORM_CONFIGS.BODY_FAT.unit}
            isLimitDecimal
            maxLengthInput={MAX_LENGTH_BODY_FAT}
          />
        </Form.Item>

        {/* Pressure */}
        <Form.Item
          {...BODY_INDEX_FORM_CONFIGS.PRESSURE}
          style={{ marginBottom: 0 }}
        >
          <Row>
            {/* Systolic */}
            <Col span={12}>
              <Form.Item
                labelCol={{ span: 0 }}
                wrapperCol={{ span: 24 }}
                {...BODY_INDEX_FORM_CONFIGS.SYSTOLIC_PRESSURE}
              >
                <AppInputNumber
                  suffix={BODY_INDEX_FORM_CONFIGS.SYSTOLIC_PRESSURE.unit}
                  isLimitDecimal
                  maxLengthInput={MAX_LENGTH_PRESSURE_SYSTOLIC}
                />
              </Form.Item>
            </Col>

            {/* Diastolic */}
            <Col span={12}>
              <Form.Item
                labelCol={{ span: 0 }}
                wrapperCol={{ span: 24 }}
                {...BODY_INDEX_FORM_CONFIGS.DIASTOLIC_PRESSURE}
              >
                <AppInputNumber
                  suffix={BODY_INDEX_FORM_CONFIGS.DIASTOLIC_PRESSURE.unit}
                  isLimitDecimal
                  maxLengthInput={MAX_LENGTH_PRESSURE_DIASTOLIC}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>

        {/* Heartbeat */}
        <Form.Item {...BODY_INDEX_FORM_CONFIGS.HEARTBEAT}>
          <AppInputNumber
            suffix={BODY_INDEX_FORM_CONFIGS.HEARTBEAT.unit}
            isLimitDecimal
            maxLengthInput={MAX_LENGTH_HEART_BEAT}
          />
        </Form.Item>
      </FormStyled>

      {/* Action */}
      <ActionWrapperStyled justify="end">
        <Col>
          <Button type="primary" htmlType="submit" form={FORM_NAME}>
            {/* !Add space end of text => to fix issue `auto add space between 2 chars in button` of antd */}
            {submitText}
            {""}
          </Button>
        </Col>
      </ActionWrapperStyled>
    </Fragment>
  );
}

export { BodyIndexForm };
