import { Input, InputProps, InputRef } from "antd";
import {
  NUMBER_ONLY_REGEX,
  NUMBER_REGEX,
  NUMBER_REGEX_2_DECIMAL,
} from "constants/common";
import React, { Ref } from "react";

function checkStringNumber(
  value: string,
  {
    isLimitDecimal,
    onlyNumber,
  }: { isLimitDecimal?: boolean; onlyNumber?: boolean }
) {
  if (onlyNumber) {
    if (NUMBER_ONLY_REGEX.test(value)) return true;

    return false;
  }

  if (isLimitDecimal) {
    if (NUMBER_REGEX_2_DECIMAL.test(value)) return true;

    return false;
  }

  if (NUMBER_REGEX.test(value)) {
    return true;
  }
  return false;
}

type TAppInputNumberMainProps = InputProps & {
  isLimitDecimal?: boolean;
  onlyNumber?: boolean;
  maxLengthInput?: number;
};

function AppInputNumberMain(
  props: TAppInputNumberMainProps,
  ref?: Ref<InputRef>
) {
  const {
    onChange,
    id,
    isLimitDecimal,
    onlyNumber,
    maxLengthInput,
    value = "",
    ...restProps
  } = props;

  return (
    <Input
      {...restProps}
      id={id}
      ref={ref}
      value={value}
      onChange={(e) => {
        const isValidNumber = checkStringNumber(e.target.value, {
          isLimitDecimal,
          onlyNumber,
        });

        if (!isValidNumber) {
          e.preventDefault();
        } else {
          onChange?.(e);
        }
      }}
      maxLength={maxLengthInput}
    />
  );
}

const AppInputNumber = React.forwardRef(AppInputNumberMain);

export { AppInputNumber };
